import resultBg from '../../images/black.png'
// import * as React from "react";
import './encounter-ring.css';
// import encounterBG from '../../images/blackripple.gif'
import encounterBG from '../../images/bg0.png'
import React, { useState, useEffect } from 'react'
import tutorial0 from '../../images/tutorials/tutorial0.png';
import emerald from '../../images/misc_crystal_new.png'
import pearl from '../../images/stone-soup/misc_crystal_old.png';
import folamh from '../../images/about2/stone-0.png'
import fromOne from '../../images/about2/stone-1.png'
import fromTwo from '../../images/about2/stone-2.png'
import fromThree from '../../images/about2/stone-3.png'
import fromFour from '../../images/about2/stone-4.png'
import fromFive from '../../images/about2/stone-5.png'
import fromSix from '../../images/about2/stone-6.png'
import fromSeven from '../../images/about2/stone-7.png'
import druids from '../../images/gael-ring/druids.png'
import glass from '../../images/bg1.png';
import witch0 from '../../images/ai-art/npcs/witch0.jpg'
import mash from '../../images/gifs/mash.gif';


import './encounter.css';
import './encounter-ring.css';
import {
	CircularInput,
  CircularTrack,
  CircularThumb,
} from "react-circular-input";

import encounter from './encounters.json'

export default function Encounter({ hideEncounterComponent}) {
// let storedEncounterID = localStorage.getItem('encounterID');
// 	let encounterID = 0;
	
	return null
};